export type Config = typeof config;

const config = {
  apiURL: "http://localhost:9000",
  nextGenApiURL: "http://localhost:8100",
  // nextGenApiURL: "https://fresh.jpaccessory.co.th",
  developmentMode: true,
  sentryDNS: "",
  features: {
    trade_route: true,
    customer_images: true,
    bill_images: false,
    daily_report: true,
    stock_summary_report: true,
    customer_stock_summary_report: true,
    add_item_with_pics: true,
  },
};

export default config;
